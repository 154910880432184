<script lang="ts">
	import { Dialog as DialogPrimitive } from 'bits-ui'

	import { cn } from '$lib/utils/shadcn'

	type $$Props = DialogPrimitive.TitleProps

	let className: $$Props['class'] = undefined
	export { className as class }
</script>

<DialogPrimitive.Title
	class={cn(
		'text-center text-xl font-semibold leading-none tracking-tight sm:text-left',
		className
	)}
	{...$$restProps}
>
	<slot />
</DialogPrimitive.Title>
